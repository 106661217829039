<template>
  <footer>
    <template v-if="supportRoutes.indexOf($route.name) >= 0">
      <support-bottom-menu />
    </template>
    <template
      v-if="
        currentTime >= 10 &&
        currentTime < 17 &&
        (dayNumber !== 0 || dayNumber !== 6)
      "
    >
      <div @click="openChat" class="support-helper-btn">
        <template v-if="supportsOnline">
          <span class="support-text">
            {{ $t("components.footer.techSupport") }}
          </span>
        </template>
      </div>
    </template>
    <template v-else>
      <template v-if="supportRoutes.indexOf($route.name) < 0">
        <div class="support-off itserve-center">
          {{ $t("global.supportOff") }}
        </div>
      </template>
    </template>
  </footer>
</template>
<script>
import SupportBottomMenu from "@/components/support/SupportBottomMenu";
import appBridge from "@/mixins/app-bridge";
import Vue from "vue";
import store from "@/store";
import Tawk from "@/plugins/tawk";

export default {
  mixins: [appBridge],
  name: "AppFooter",
  data() {
    return {
      currentTime: 0,
      supportsOnline: true,
      dayNumber: 0,
      supportRoutes: ["Contacts", "WriteTicket", "SupportPage"],
    };
  },
  components: {
    SupportBottomMenu,
  },
  created() {
    this.calcTime("+2");
    if (
      this.currentTime >= 10 &&
      this.currentTime < 17 &&
      (this.dayNumber !== 0 || this.dayNumber !== 6)
    ) {
      Vue.use(Tawk, {
        tawkSrc: "https://embed.tawk.to/640201964247f20fefe3c95d/1gqjtcdml",
      });
      window.Tawk_API.onLoad = () => {
        store.dispatch("Auth/setUserPhone");
        if (window.Tawk_API.getStatus() === "offline") {
          this.supportsOnline = false;
        }
      };
    }
  },
  methods: {
    calcTime(offset) {
      let d = new Date();
      let utc = d.getTime() + d.getTimezoneOffset() * 60000;
      let nd = new Date(utc + 3600000 * offset);
      this.currentTime = nd.getHours();
      this.dayNumber = nd.getDay();
    },
    async openChat() {
      try {
        await store.dispatch("Auth/setUserPhone");
        window.Tawk_API.maximize();
      } catch (e) {
        this.nativeSendEvent("tawkSupport");
      }
    },
  },
};
</script>
<style scoped>
footer {
  background-color: #fff;
}
.support-helper-btn {
  position: relative;
  height: 75px;
  display: inline-block;
  line-height: 75px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  border-radius: 5px;
}
.arrow-right-icon {
  margin-top: 5px;
}
.support-text {
  color: #1d1d1d;
}
.support-off {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
